import { value } from "lodash/seq";
import request from "@/utils/request";
import axios from "axios";
import { getToken } from "@/utils/auth";

//区域门店
export function getOrderStore(params) {
  return request({
    url: "/api/store/orderStore/relevanceStore",
    method: "get",
    data: params,
  });
}

//配送平台列表
export function getOrdFreightPlatform(data) {
  return request({
    url: `/api/order/ordFreightPlatform?pageNum=${data.pageNum}&pageSize=${data.pageSize}&search=${data.search}`,
    method: "get",
  });
}

//新增配送平台
export function addOrdFreightPlatform(data) {
  return request({
    url: "/api/order/ordFreightPlatform",
    method: "post",
    data: data,
  });
}

//编辑配送平台
export function editOrdFreightPlatform(data) {
  return request({
    url: `/api/order/ordFreightPlatform/${data.sid}`,
    method: "put",
    data: data,
  });
}

//查看配送平台详情
export function getOrdFreightPlatformDetail(sid) {
  return request({
    url: `/api/order/ordFreightPlatform/${sid}`,
    method: "get",
  });
}

//删除配送平台
export function deleteOrdFreightPlatform(sid) {
  return request({
    url: `/api/order/ordFreightPlatform/${sid}`,
    method: "delete",
  });
}

//骑手列表
export function getOrdFreightRiderList(data) {
  return request({
    url: `/api/order/ordFreightRider/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//新增骑手
export function addOrdFreightRider(data) {
  return request({
    url: "/api/order/ordFreightRider",
    method: "post",
    data: data,
  });
}

//编辑骑手
export function editOrdFreightRider(data) {
  return request({
    url: `/api/order/ordFreightRider/${data.sid}`,
    method: "put",
    data: data,
  });
}

//骑手详情
export function getOrdFreightRiderDetail(sid) {
  return request({
    url: `/api/order/ordFreightRider/${sid}`,
    method: "get",
  });
}

//删除骑手
export function deleteOrdFreightRider(sid) {
  return request({
    url: `/api/order/ordFreightRider/${sid}`,
    method: "delete",
  });
}

//自取货骑手列表
export function getOrdFreightRiderSelfList(data) {
  return request({
    url: `/api/order/ordFreightRiderSelf/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//删除自取货骑手
export function deleteOrdFreightRiderSelf(sid) {
  return request({
    url: `/api/order/ordFreightRiderSelf/${sid}`,
    method: "delete",
  });
}

//获取骑手结算设置
export function getOrdFreightRiderSettle(sid) {
  return request({
    url: `/api/order/ordFreightRider/${sid}/settle`,
    method: "get",
  });
}

//设置骑手结算设置
export function setOrdFreightRiderSettle(data) {
  return request({
    url: `/api/order/ordFreightRider/${data.sid}/settle`,
    method: "put",
    data: data,
  });
}

//添加自取货骑手
export function addOrdFreightRiderSelf(data) {
  return request({
    url: "/api/order/ordFreightRiderSelf",
    method: "post",
    data: data,
  });
}

//运单列表
export function getOrdFreightList(data) {
  return request({
    url: `/api/order/ordFreight/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//运单详情
export function getOrdFreightDetail(sid) {
  return request({
    url: `/api/order/ordFreight/${sid}`,
    method: "get",
  });
}

//自取货运单列表
export function getOrdFreightFetchList(data) {
  return request({
    url: `/api/order/ordFreight/list/fetch?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//取货单明细详情
export function getOrdPickingDetail(sid) {
  return request({
    url: `/api/order/ordPicking/selectById?sid=${sid}`,
    method: "get",
  });
}

//地区下关联门店详情
export function getOrderStoreDetail(data) {
  return request({
    url: "/api/store/orderStore/queryStoreById",
    method: "post",
    data: data,
  });
}

//获取运单评价
export function getOrdFreightAppraise(sid) {
  return request({
    url: `/api/order/ordFreight/${sid}/appraise`,
    method: "get",
  });
}

//设置运单评价
export function setOrdFreightAppraise(data) {
  return request({
    url: `/api/order/ordFreight/${data.sid}/appraise`,
    method: "put",
    data: data,
  });
}

//取消运单
export function cancelOrdFreight(sid) {
  return request({
    url: `/api/order/ordFreight/${sid}/cancel`,
    method: "put",
  });
}

//完成运单
export function completeOrdFreight(sid) {
  return request({
    url: `/api/order/ordFreight/${sid}/complete`,
    method: "put",
  });
}

//骑手云效分析列表
export function getOrdFreightAnalyseRiderList(data) {
  return request({
    url: `/api/order/ordFreightAnalyse/rider?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//骑手云效分析列表-自取货
export function getOrdFreightAnalyseRiderSelfList(data) {
  return request({
    url: `/api/order/ordFreightAnalyse/rider/self?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//门店云效分析列表
export function getOrdFreightAnalyseStoreList(data) {
  return request({
    url: `/api/order/ordFreightAnalyse/store?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data: data,
  });
}

//渠道云效分析列表
export function getOrdFreightAnalyseChannelList(data) {
  return request({
    url: `/api/order/ordFreightAnalyse/channel?pageNum=${data.pageNum}&pageSize=${data.pageSize}&search=${data.search}&rChannel=${data.rChannel}`,
    method: "get",
    data: data,
  });
}

//获取渠道列表
export function getOrdPickingAllChannelList() {
  return request({
    url: "/api/order/ordPicking/selectAllChannel",
    method: "get",
  });
}

//店铺列表
export function getStoShopList() {
  return request({
    url: "/api/order/stoShop/queryList",
    method: "get",
  });
}

//生成单号
export function generateOrdFreightNumber() {
  return request({
    url: "/api/order/ordFreight/generateNumber",
    method: "post",
  });
}

//发单
export function addOrdFreight(data) {
  return request({
    url: "/api/order/ordFreight",
    method: "post",
    data: data,
  });
}

//拣货单列表
export function getOrdPickingSelectWaitList(data) {
  return request({
    url: `/api/order/ordPicking/selectWaitList?search=${data.search}&rChannel=${data.rChannel}`,
    method: "get",
  });
}


const deliveryPlatform = {
  fengka: "蜂鸟KA",
  bingex: "闪送",
  dada: "达达",
  shunfeng: "顺丰",
  uupt: "UU跑腿",
  mtps: "美团配送",
  caocao: "曹操",
  ipaotui: "爱跑腿",
  fuwu: "快服务",
  caosong: "曹操送",
  sanliuwu: "365跑腿",
  bangla: "帮啦",
  fengnew: "蜂鸟配送",
  keloop: "快跑者",
  guoxiaodi: "裹小递",
  haoji: "好急",
  heiqi: "黑骑配送",
  xiakesong: "同达快送",
  dadac: "达达急送",
  huolala: "货拉拉",
  huollpt: "货拉拉跑腿",
};
export default deliveryPlatform;
